import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import Link from '../Link';
import CheckList from '../CheckList';
import MicroText from '../MicroText';

export const PARAGRAPH_AS_TEXT = {
  [BLOCKS.PARAGRAPH]: (node, children) => children
};

export const LINK = {
  [INLINES.HYPERLINK]: (node, children) => (
    <Link to={node.data.uri}>{children}</Link>
  )
};

export const EMBEDDED_ENTRY_TEXT = {
  [INLINES.EMBEDDED_ENTRY]: node => {
    if (typeof node.data.target !== 'undefined') {
      return renderRichText(node.data.target.text, {
        renderNode: { ...LINK }
      });
    }
    return null;
  }
};

export const MICRO_OL_LIST = {
  [BLOCKS.OL_LIST]: (node, children) => (
    <MicroText as="ol">{children}</MicroText>
  )
};

export const ENTRY_LINK = {
  [INLINES.ENTRY_HYPERLINK]: node => {
    const file = node.data.target;
    const text = node.content[0].value;
    const url = file.pageUrl || file.url;
    return <Link to={url}>{text}</Link>;
  }
};

export const CHECKLIST = {
  [BLOCKS.UL_LIST]: (node, children) => <CheckList>{children}</CheckList>
};

export const PARAGRAPH_AS_MICROTEXT = {
  [BLOCKS.PARAGRAPH]: (node, children) => <MicroText>{children}</MicroText>
};
