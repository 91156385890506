import React from 'react';
import { Row, Col } from 'reactstrap';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Tile from './Tile';
import Section from '../../Common/Section';
import SectionHeader from '../../Common/Header/SectionHeader';

export default ({ id, header, tiles }) => {
  return (
    <Section id={id}>
      <div className="container-lg">
        <SectionHeader header={header} />
        <Row>
          {tiles.map((tile, i) => (
            <Col md={6} lg={4} key={i} className="mb-4">
              <Tile
                title={tile.header}
                body={renderRichText(tile.description)}
                cta={tile.primaryCta}
                ctaUrl={
                  tile.primaryCtaDestination && tile.primaryCtaDestination.url
                }
              />
            </Col>
          ))}
        </Row>
      </div>
    </Section>
  );
};
