import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { FiChevronRight } from 'react-icons/fi';
import device from '../../Common/Device';

const StyledDropdown = styled(Dropdown)`
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #000;
  color: #000;
  width: 100%;
  padding: 10px 0;
  margin: auto;

  button.btn {
    font-weight: bold;
    text-align: left;
    padding: 0;
    width: 100%;
  }

  button.btn:focus {
    outline: none;
    box-shadow: none;
  }

  @media ${device.min.md} {
    display: none !important;
  }
`;

const StyledDropMenu = styled(DropdownMenu)`
  width: 100%;

  &.dropdown-menu {
    margin-top: 10px;
  }
`;

const Arrow = styled(FiChevronRight)`
  position: absolute;
  color: #000;
  width: 20px;
  height: 20px;
  left: 94.5%;
  right: 12.5%;
  bottom: 25%;
  top: 33.33%;
  transform: ${props => (props.open ? 'rotate(270deg)' : 'rotate(90deg)')};
`;

export default ({ selectedItem, setSelectedItem, resourceTypes }) => {
  const [open, setOpen] = useState(false);

  return (
    <StyledDropdown isOpen={open} toggle={() => setOpen(!open)}>
      <DropdownToggle color="#000">
        {selectedItem}
        <Arrow open={open} onClick={() => setOpen(!open)} />
      </DropdownToggle>
      <StyledDropMenu>
        {resourceTypes.map(resource => (
          <DropdownItem onClick={() => setSelectedItem(resource.tabHeader)}>
            {resource.tabHeader}
          </DropdownItem>
        ))}
      </StyledDropMenu>
    </StyledDropdown>
  );
};
