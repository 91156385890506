import React from 'react';
import styled from 'styled-components';
import LearnMore from '../../Common/LearnMore';
import Link from '../../Common/Link';
import device from '../../Common/Device';

const InfoContainer = styled.div`
  text-align: center;
  top: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  min-height: 347px;
  height: 100%;
  padding: 48px 24px 32px 24px;
  transition: ease-out 0.2s;
  color: #000;

  h4 {
    margin-bottom: 24px;
  }

  &:hover {
    color: #000;
    text-decoration: none;
  }

  @media ${device.only.sm} {
    min-height: 280px;
  }
`;

const LinksContainer = styled.div`
  margin: 0 auto;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 347px;

  &:hover {
    a {
      top: -5px;
      transition: ease-in 0.2s;
    }
  }

  @media ${device.only.lg} {
    min-height: 380px;
  }

  @media ${device.only.sm} {
    min-height: 350px;
  }
`;

export default ({ title, body, cta, ctaUrl }) => {
  return (
    <Wrapper>
      {cta && ctaUrl && (
        <InfoContainer as={Link} to={ctaUrl}>
          <div>
            <h4>{title}</h4>
            <p>{body}</p>
          </div>
          <LinksContainer>
            <LearnMore text={cta} plainText />
          </LinksContainer>
        </InfoContainer>
      )}
      {!cta && !ctaUrl && (
        <InfoContainer>
          <div>
            <h4>{title}</h4>
            <p>{body}</p>
          </div>
        </InfoContainer>
      )}
    </Wrapper>
  );
};
