import React from 'react';
import { CustomInput } from 'reactstrap';
import styled from 'styled-components';
import Check from '../../images/icons/Check.svg';

const RadioButton = styled(CustomInput)`
  padding-left: 32px;

  .custom-control-label {
    padding-top: 8px;
    padding-left: 16px;
    cursor: pointer;

    &:before {
      left: -32px;
      width: 32px;
      height: 32px;
      margin-left: 8px;
      border: 2px solid #776e64;
      background-color: transparent;
    }

    &:after {
      left: -32px;
      width: 32px;
      height: 32px;
      margin-left: 8px;
    }
  }

  .custom-control-input {
    &:not(:disabled):active {
      & ~ .custom-control-label {
        &:before {
          background-color: #00a4b3;
          border-color: #00a4b3;
        }
      }
    }

    &:focus,
    &:hover {
      & ~ .custom-control-label {
        &:before {
          box-shadow: none;
          border: 2px solid #00a4b3;
        }
      }
    }

    &:checked {
      & ~ .custom-control-label {
        &:before {
          background-color: #00a4b3;
          border-color: #00a4b3;
        }

        &:after {
          background-image: url(${Check}) !important;
          background-size: 60% 50%;
        }
      }
    }

    &:disabled {
      & ~ .custom-control-label {
        cursor: not-allowed;
        color: #000;
        opacity: 0.3;

        &:before {
          border: 2px solid #776e64;
          background-color: transparent;
        }
      }
    }

    &:disabled:checked {
      & ~ .custom-control-label {
        &:before {
          background-color: #00a4b3 !important;
          border-color: #00a4b3 !important;
        }
      }
    }
  }
`;

export default ({
  name,
  value,
  label,
  checked,
  inline = 'false',
  onClick,
  ...props
}) => (
  <RadioButton
    type="radio"
    id={`${name}-${value}`}
    name={name}
    value={value}
    label={label}
    checked={checked}
    inline={inline}
    onClick={onClick}
    {...props}
  />
);
