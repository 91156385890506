import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import SectionHeader from '../../Common/Header/SectionHeader';
import Section from '../../Common/Section';
import device from '../../Common/Device';
import Tabs from './Tabs';
import DropDownMenu from './DropDownMenu';
import ResourceContainer from './ResourceContainer';

const ListContainer = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e5e5e5;

  @media ${device.max.sm} {
    display: none !important;
  }
`;

export default ({ id, header, resourceTypes }) => {
  // set selectedItem initial state to be first resource Item
  const [selectedItem, setSelectedItem] = useState(resourceTypes[0].tabHeader);
  return (
    <Section id={id}>
      <div className="container-lg">
        <SectionHeader header={header} />
        <Row>
          <Col xs={12}>
            {/* List for filter of product type */}
            <ListContainer>
              <Tabs
                resourceTypes={resourceTypes}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
              />
            </ListContainer>
            {/* Mobile view DropDown */}
            <DropDownMenu
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              resourceTypes={resourceTypes}
            />
          </Col>
        </Row>
        <ResourceContainer
          selectedItem={selectedItem}
          resourceTypes={resourceTypes}
        />
      </div>
    </Section>
  );
};
