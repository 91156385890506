import React from 'react';
import styled from 'styled-components';
import { FiChevronRight, FiExternalLink } from 'react-icons/fi';
import Link from './Link';
import Information from '../../images/icons/Information.svg';

const StyledLink = styled(Link)`
  border-bottom: 1px solid transparent;

  &:hover {
    text-decoration: none;
    border-bottom: 1px solid #007b86;
  }
`;

const StyledText = styled.span`
  color: #007b86;
  border-bottom: 1px solid transparent;
`;

const RightArrow = styled(FiChevronRight)`
  color: #007b86;
  width: 22px;
  height: 22px;
`;

const ExternalLinkIcon = styled(FiExternalLink)`
  color: #007b86;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  vertical-align: baseline;
  margin-bottom: -1px;
`;

const InfoIcon = styled.span`
  width: 16px;
  height: 16px;
  background: url(${Information}) no-repeat left;
  padding: 12px;
`;

const Container = styled.div`
  display: inline;
`;

const IconWrapper = styled.span`
  display: inline;
  white-space: nowrap;
`;

export default ({
  text = 'Learn more',
  url,
  className,
  plainText = false,
  external = false,
  info = false,
  openNewTab = false
}) => (
  <Container className={className}>
    {info && <InfoIcon />}
    {!plainText && !openNewTab && <StyledLink to={url}>{text}</StyledLink>}
    {!plainText && openNewTab && (
      <StyledLink
        to={url}
        target="_blank"
        rel="noopener noreferrer"
        className={`${className}-link`}>
        {text}
      </StyledLink>
    )}
    {plainText && <StyledText>{text}</StyledText>}
    {!external && !info && (
      <IconWrapper>
        &#65279;
        <RightArrow />
      </IconWrapper>
    )}
    {external && (
      <IconWrapper>
        &#65279;
        <ExternalLinkIcon />
      </IconWrapper>
    )}
  </Container>
);
