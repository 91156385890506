import React from 'react';
import styled from 'styled-components';
import { Nav, NavItem, NavLink } from 'reactstrap';
import device from '../../Common/Device';

const StyledNavItem = styled(NavItem)`
  margin: 0;

  ${props =>
    props.activeItem
      ? `
    a {
      border-bottom: 4px solid #ca1905;
      font-weight: bold;
      color: #000 !important;
    }
  `
      : ``}
`;

const StyledNavLink = styled(NavLink)`
  font-weight: normal;
  color: #776e64 !important;
  cursor: pointer;
  text-align: center;
  padding: 16px 8px !important;
  margin: 0 8px;
  min-width: 290px;
  min-height: 65px;

  &:hover {
    border-bottom: 4px solid #ca1905;
    font-weight: bold;
    color: #000 !important;
  }

  @media ${device.max.md} {
    min-width: 200px;
  }
`;

export default ({ resourceTypes, selectedItem, setSelectedItem }) => {
  return (
    <Nav>
      {resourceTypes.map((resourceType, index) => (
        <StyledNavItem
          key={index}
          activeItem={selectedItem === resourceType.tabHeader}>
          <StyledNavLink
            onClick={() => {
              setSelectedItem(resourceType.tabHeader);
            }}>
            {resourceType.tabHeader}
          </StyledNavLink>
        </StyledNavItem>
      ))}
    </Nav>
  );
};
