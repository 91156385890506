import React from 'react';
import styled from 'styled-components';
import LinkButton from '../../Buttons/LinkButton';

const Container = styled.div`
  background: #f7f7f7;
  padding: 24px;
  margin-top: 40px;
`;

export default ({ header, body, cta, ctaUrl }) => {
  return (
    <Container>
      <h5>{header}</h5>
      {body}
      <LinkButton to={ctaUrl}>{cta}</LinkButton>
    </Container>
  );
};
