import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavLink,
  NavItem
} from 'reactstrap';
import { animateScroll as scroll } from 'react-scroll';
import { FiChevronRight } from 'react-icons/fi';
import device from '../Device';
import getElementPosition from '../../../utils/getElementPosition';
import getElementHeight from '../../../utils/getElementHeight';

const StyledDropdown = styled(Dropdown)`
  position: static !important;

  .dropdown-menu {
    a:last-child {
      padding: 18px 1.5rem;
    }

    .dropdown-item {
      padding: 18px 1.5rem;
      text-align: center;
    }

    a:focus,
    a:hover {
      background: #fff;
      color: #000;
      outline: none;
      box-shadow: none;
    }

    margin: 0;
    border: none;
    border-radius: 0;
    padding: 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  }
`;

const StyledDropdownToggle = styled(DropdownToggle)`
  color: #000;
  font-weight: bold;
  height: 24px;
  margin: 18px 0;
  display: inline-block;
  padding: 0 !important;

  &:focus,
  &:hover {
    color: #000;
  }
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  width: 100%;
`;

const StyledDropdownItem = styled(DropdownItem)`
  color: #000;
`;

const Arrow = styled(FiChevronRight)`
  color: #000;
  width: 20px;
  height: 20px;
  margin-top: -2px;
  margin-left: 5px;
  transform: ${props => (props.open ? 'rotate(270deg)' : 'rotate(90deg)')};
`;

const StyledNav = styled(Nav)`
  @media ${device.max.sm} {
    display: none !important;
  }

  margin: 0 auto;
  padding: 0;
  flex-wrap: nowrap;
`;

const StyledNavLink = styled(NavLink)`
  text-align: center;
  min-width: 150px !important;
  height: 70px;
  padding: 23px 0 !important;
  margin: 0 10px;
  color: #000;

  &:hover {
    color: #000;
    border-bottom: 4px solid #ca1905;
    font-weight: bold;
  }

  @media ${device.max.sm} {
    min-width: 80px !important;
    margin: 0;
  }
`;

const StyledNavItem = styled(NavItem)`
  margin: 0;

  &.active-menu-section a {
    border-bottom: 4px solid #ca1905;
    font-weight: bold;
  }
`;

const DropdownNav = styled(Nav)`
  margin: 0 auto;
  align-items: center;
  padding: 0;
  height: 70px;

  li {
    margin: 0;
  }

  @media ${device.min.md} {
    display: none !important;
  }
`;

export default ({ sections }) => {
  const [current, setCurrent] = useState(sections[0].name);
  const [open, setOpen] = useState(false);
  const [target, setTarget] = useState(null);

  const isInViewport = element => {
    const position = getElementPosition(element);
    if (!position) {
      return false;
    }

    return (
      position.bottom > getElementHeight('#section-nav') + 1 &&
      position.top <=
        (window.innerHeight || document.documentElement.clientHeight)
    );
  };

  const scrollTo = (event, element) => {
    event.preventDefault();

    const position = getElementPosition(element);
    if (!position) {
      return;
    }

    scroll.scrollTo(
      position.top + window.pageYOffset - getElementHeight('#section-nav')
    );
  };

  useEffect(() => {
    const handler = () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const section of sections) {
        if (isInViewport(section.element)) {
          if (!target) {
            setCurrent(section.name);
          } else if (target === section.name) {
            setCurrent(section.name);
            setTarget(null);
          }
          break;
        }
      }
    };
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  });

  const isActive = name => {
    if (target) {
      return target === name;
    }
    return current === name;
  };

  return (
    <>
      <StyledNav>
        {sections.map((section, index) => (
          <StyledNavItem
            key={index}
            className={isActive(section.name) ? 'active-menu-section' : ''}>
            <StyledNavLink
              href={section.element}
              onClick={event => {
                setTarget(section.name);
                scrollTo(event, section.element);
              }}>
              {section.name}
            </StyledNavLink>
          </StyledNavItem>
        ))}
      </StyledNav>

      <DropdownNav>
        <StyledDropdown
          nav
          inNavbar
          isOpen={open}
          toggle={() => setOpen(!open)}>
          <StyledDropdownToggle nav>
            {current}
            <Arrow open={open} />
          </StyledDropdownToggle>
          <StyledDropdownMenu>
            {sections.map(section => (
              <StyledDropdownItem
                href={section.element}
                onClick={event => scrollTo(event, section.element)}>
                {section.name}
              </StyledDropdownItem>
            ))}
          </StyledDropdownMenu>
        </StyledDropdown>
      </DropdownNav>
    </>
  );
};
