import React from 'react';
import {
  IntroSection,
  ArticlesSection,
  CtaSection,
  ContentWithImagesSection
} from '@vancity/react-component-library';
import { MARKS } from '@contentful/rich-text-types';
import styled from 'styled-components';
import Sections from '../Common/Sections';
import SectionNav from '../Common/SectionNav/SectionNav';
import ManageFinance from './ManageFinance/ManageFinance';
import HelpfulResources from './HelpfulResources/HelpfulResources';
import Legal from '../Common/Legal/Legal';
import Parallax from '../Parallax/Parallax';

const StyledItalics = styled.i`
  text-decoration: line-through;
`;

export default ({
  sections,
  parallaxData,
  introData,
  articlesData,
  ctaData,
  listenData,
  financeData,
  tabsData,
  legalData
}) => {
  const options = {
    renderMark: {
      [MARKS.ITALIC]: text => <StyledItalics>{text}</StyledItalics>
    }
  };

  return (
    <>
      <Parallax items={parallaxData.specialParallaxCarouselItem} />
      <SectionNav sections={sections} />
      <Sections>
        <IntroSection
          id="intro"
          header={introData.header}
          body={introData.body}
          textOptions={options}
        />
        <ArticlesSection
          id="learn"
          header={articlesData.header}
          subheader={articlesData.subheader}
          articles={articlesData.relatedArticles}
        />
        <CtaSection
          id="cta"
          header={ctaData.header}
          body={ctaData.body}
          primaryCta={ctaData.primaryCta}
          primaryCtaUrl={ctaData.primaryCtaDestination.url}
          secondaryCta={ctaData.secondaryCta}
          secondaryCtaUrl={ctaData.secondaryCtaDestination.url}
        />
        <ContentWithImagesSection
          id="listen"
          header={listenData.header}
          subheader={listenData.subheader}
          items={listenData.components}
        />
        <ManageFinance
          id="resources"
          header={financeData.header}
          tiles={financeData.tiles}
        />
        <HelpfulResources
          id="community"
          header={tabsData.header}
          resourceTypes={tabsData.tabs}
        />
        <Legal legalText={legalData.legalDisclaimers} />
      </Sections>
    </>
  );
};
