import styled from 'styled-components';
import checkmark from '../../images/icons/Checkmark.svg';

const CheckList = styled.ul`
  text-align: left;
  list-style-type: none;
  margin: 0 0 24px 0;
  color: #000;
  padding-left: 0;
  white-space: pre-line;

  li {
    padding-left: 28px;
    background-image: url(${checkmark});
    background-position: 0 0.3rem;
    background-size: 18px;
    background-repeat: no-repeat;
    margin-bottom: 24px;

    p {
      display: block;
    }
  }

  li:last-child {
    margin-bottom: 0;
  }
`;

export default CheckList;
