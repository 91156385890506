import React, { useState } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import RadioButton from '../../RadioButton';
import Option from './Option';
import LinkButton from '../../Buttons/LinkButton';
import { ENTRY_LINK, CHECKLIST } from '../../RichTextOptions/Nodes';

export default ({ header, body, options, cta, ctaDestination }) => {
  const [activeOption, setActiveOption] = useState(null);

  const labelOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <label htmlFor="modalOption">{children}</label>
      )
    }
  };

  return (
    <div>
      <h4>{header}</h4>
      {options && renderRichText(body, labelOptions)}
      {!options &&
        renderRichText(body, {
          renderNode: {
            ...ENTRY_LINK,
            ...CHECKLIST
          }
        })}
      {cta && (
        <LinkButton to={ctaDestination && ctaDestination.url}>{cta}</LinkButton>
      )}
      {options &&
        options.map(option => (
          <RadioButton
            name="modalOption"
            checked={activeOption === option.subModalOptionDisplay}
            value={option.subModalOptionDisplay}
            label={option.subModalOptionDisplay}
            onClick={() => setActiveOption(option.subModalOptionDisplay)}
          />
        ))}
      {options &&
        options.map(
          option =>
            option.subModalOptionDisplay === activeOption && (
              <Option
                header={option.modalHeader}
                body={renderRichText(option.modalBody, {
                  renderNode: {
                    ...ENTRY_LINK,
                    ...CHECKLIST
                  }
                })}
                cta={option.primaryCta}
                ctaUrl={
                  option.primaryCtaDestination &&
                  option.primaryCtaDestination.url
                }
              />
            )
        )}
    </div>
  );
};
