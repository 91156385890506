import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import Section from '../Section';
import { LINK, MICRO_OL_LIST } from '../RichTextOptions/Nodes';
import MicroText from '../MicroText';

const LegalParagraph = styled(MicroText)`
  & > p:first-child {
    display: inline;

    :before {
      content: ' ';
    }
  }
`;

export const legalTextOptions = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: node => {
      if (typeof node.data.target && node.data.target.text) {
        return renderRichText(node.data.target.text, legalTextOptions);
      }
      return null;
    },
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <LegalParagraph>{children}</LegalParagraph>
    ),
    ...MICRO_OL_LIST,
    ...LINK
  }
};

export default ({ legalText }) => {
  return (
    <Section>
      <div className="container-lg">
        <Row>
          <Col>{renderRichText(legalText, legalTextOptions)}</Col>
        </Row>
      </div>
    </Section>
  );
};
