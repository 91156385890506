import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'reactstrap';
import { FiX } from 'react-icons/fi';
import Button from '../Buttons/Button';
import device from '../Device';

const Container = styled.div`
  margin: 56px;
  margin-top: 0;

  @media ${device.max.xs} {
    margin: 24px;
  }
`;

const Header = styled.div`
  margin: 21px;
`;

const Close = styled.button`
  background: none;
  border: none;
  float: right;
  color: #776e64;
  padding: 0;
  margin: 0;
  height: 14px;
  width: 14px;

  svg {
    vertical-align: top;
    width: 100%;
    height: 100%;
  }
`;

export default ({ label, children, className }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <Button className={className} onClick={toggle}>
        {label}
      </Button>
      <Modal isOpen={open} toggle={toggle}>
        <Header>
          <Close type="button" aria-label="Close" onClick={toggle}>
            <FiX />
          </Close>
        </Header>
        <Container>{children}</Container>
      </Modal>
    </>
  );
};
