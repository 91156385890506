import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Button from '../Common/Buttons/Button';
import device from '../Common/Device';
import MicroText from '../Common/MicroText';
import middle from '../../images/walk/layers/middle_desktop.png';
import front from '../../images/walk/layers/front_desktop.png';
import street from '../../images/walk/layers/street_desktop.jpg';
import middleMobile from '../../images/walk/layers/middle_mobile.png';
import frontMobile from '../../images/walk/layers/front_mobile.png';
import streetMobile from '../../images/walk/layers/street_mobile.jpg';

const ImageContainer = styled.div`
  position: relative;
  height: 600px;

  @media ${device.max.sm} {
    height: 310px;
  }
`;

const Layer = styled.img`
  max-height: 600px;
  bottom: 0;
  left: 0;
  position: absolute;
  transform: translateX(${props => props.offset}%)
    translateX(${props => (props.expanded ? '389px' : '300px')});
  transition: transform 1s;
  z-index: 0;

  @media ${device.max.sm} {
    transform: translateX(${props => props.offset}%);
    max-height: 310px;
  }
`;

const OverlayBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  transform: skewX(-5deg)
    translateX(
      ${props =>
        (props.screenWidth || 10000) * -1 + (props.expanded ? 414.5 : 325.5)}px
    );
  height: 100%;
  width: ${props => props.screenWidth || 10000}px;
  z-index: 1;

  @media ${device.max.sm} {
    top: initial;
    transform: skewY(5deg);
    left: 0;
    bottom: -50px;
    width: 100%;
    height: calc(100% - 225px);
  }
`;

const OverlayForeground = styled.div`
  transform: translateX(0px) skewX(-5deg);

  ${props =>
    !props.visible && props.expanded
      ? `transform: translateX(-491px) skewX(-5deg);`
      : ``}

  ${props =>
    !props.visible && !props.expanded
      ? `transform: translateX(-402px) skewX(-5deg);`
      : ``}


  transition: transform 1s, width 0.5s;
  position: absolute;
  top: 0;
  left: -25.5px;
  background: #fff;
  height: 100%;
  width: ${props => (props.expanded ? '440px' : '351px')};
  z-index: 3;

  @media ${device.max.sm} {
    top: initial;
    transform: translateX(${props => (props.visible ? '0px' : '-100%')})
      skewY(5deg);
    transition: transform 1s;
    left: 0;
    bottom: -50px;
    width: 100%;
    height: calc(100% - 225px);
  }
`;

const ParallaxContainer = styled.div`
  overflow: visible;
  position: relative;
  height: 600px;

  @media ${device.max.sm} {
    height: auto;
  }
`;

const Overlay = styled.div`
  @keyframes fade-from-left {
    from {
      transform: translateX(-50px);
      opacity: 0;
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  h4 {
    display: inline-block;
  }

  padding: 84px 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${props => (props.expanded ? '389px' : '300px')};
  transition: width 0.5s;
  z-index: 2;
  display: flex;
  flex-direction: column;
  animation: fade-from-left 1s;

  @media ${device.max.sm} {
    position: relative;
    padding: 32px 24px;
    padding-top: 0;
    width: 100%;

    p:last-of-type:not(:first-of-type) {
      margin-bottom: 0;
    }
  }
`;

const LeftArrow = styled(FiChevronLeft)`
  color: #000;
  width: 36px;
  height: 36px;
`;

const RightArrow = styled(FiChevronRight)`
  color: #000;
  width: 36px;
  height: 36px;
`;

const ArrowButton = styled.button`
  background: transparent;
  margin: 0;
  padding: 0;
  border: none;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  height: 75px;
  margin-top: auto;
  align-items: flex-start;
  margin-right: 15px;

  @media ${device.max.sm} {
    height: auto;
    margin: 0;
    justify-content: flex-end;

    button:last-child {
      margin-left: 5px;
    }
  }
`;

const StreetSign = styled(Img)`
  @keyframes move-right {
    from {
      transform: translateX(-150px);
    }

    to {
      transform: translateX(0);
    }
  }

  animation: move-right 1s;
  background: #fff;
  position: absolute !important;
  left: 341px;
  top: 84px;
  transform: ${props =>
    props.visible ? `translateX(0px)` : `translateX(-150px)`};
  transition: transform 1s;

  @media ${device.max.sm} {
    left: 0;
    top: 46px;
  }
`;

const Status = styled(MicroText)`
  ${props =>
    props.open
      ? `
      background: #bfebe2;
      color: #016951;
      `
      : `
      color: #a91504;
      background: #ffdad8;
      `}

  display: inline;
  margin: 0 0 0 8px;
  padding: 3px 6px;
  border-radius: 0px 8px;
  font-weight: bold;
  vertical-align: text-bottom;
`;

const Background = styled.div`
  overflow: hidden;
  background: linear-gradient(180deg, #add1f7 0%, #ffffff 100%);
`;

const Container = styled.div`
  @media ${device.max.sm} {
    padding: 0 !important;
  }
`;

const HeaderWithStatus = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  div {
    flex-basis: 45%;
    margin-bottom: 8px;
  }
`;

export default ({ items }) => {
  const images = useStaticQuery(graphql`
    query {
      signs: allFile(filter: { relativeDirectory: { eq: "walk/signs" } }) {
        nodes {
          name
          childImageSharp {
            fixed(height: 33, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  `);

  const signImages = images.signs.nodes;
  const signs = [null, 'main', 'beatty', 'terminal', 'granville', 'burrard'];
  const steps = [-6.75, -11.8, -25.95, -38.17, -57.2, -70.05];
  const midSteps = [-7.5, -10.75, -26.02, -40, -57.25, -70.5];
  const frontSteps = [-5, -11.38, -20.55, -27.55, -31, -70.6];
  const [index, setIndex] = useState(0);
  const [step, setStep] = useState(0);
  const foreground = useRef(null);
  const [foregroundVisible, setForegroundVisible] = useState(false);
  const [foregroundResizing, setForegroundResizing] = useState(false);
  const [foregroundExpanded, setForegroundExpanded] = useState(true);
  const [backgroundExpanded, setBackgroundExpanded] = useState(true);
  const [streetSign, setStreetSign] = useState(null);

  const mediaQuery =
    typeof window !== 'undefined' && window && window.matchMedia(device.max.sm);
  const [isMobile, setMobile] = useState(mediaQuery.matches);
  useEffect(() => {
    const handler = () => {
      setMobile(mediaQuery.matches);
    };

    mediaQuery.addListener(handler);

    return () => {
      mediaQuery.removeListener(handler);
    };
  }, [mediaQuery]);

  useEffect(() => {
    // This runs every time a foreground transition animation occurs, this can be either the
    // sliding transition or the resizing transition. We only care when the foreground is
    // visible as this is when we can decide whether to resize or move to the next content.
    const transition = () => {
      if (foregroundVisible) {
        // if we're moving away from first, the foreground is going to shrink, shrink the
        // background so it's out of the way
        if (!isMobile && index > 0) {
          setBackgroundExpanded(false);
        }

        // If the animation was a resize, it's now done so set it to false and continue to
        // update the current item and hide the foreground
        if (foregroundResizing) {
          setForegroundResizing(false);
          // once a resize is done, if it's an expansion (i.e. going to the first item)
          // expand the background before we unhide the foreground
          if (!isMobile && index < 1) {
            setBackgroundExpanded(true);
          }
          // Animation wasn't a resize - if we're not on mobile and we're currently on the first item
          // OR we're about to move to the first item, we need to expand or shrink the overlay, indicate
          // that a resize animation is occurring and not hide the foreground yet
        } else if (!isMobile && (step === 0 || index === 0)) {
          setForegroundResizing(true);
          setForegroundExpanded(!foregroundExpanded);
          return;
        }

        // If resizing is done, or we're on mobile, or we're moving between items that aren't
        // the first item, update the current sign, content and hide the foreground
        const sign = signImages.find(image => image.name === signs[index]);
        if (sign) {
          setStreetSign(sign);
        }
        setStep(index);
        setForegroundVisible(false);
      }
    };

    const el = foreground.current;
    if (el) {
      el.addEventListener('transitionend', transition);
    }

    return () => {
      el.removeEventListener('transitionend', transition);
    };
  });

  const doc =
    typeof document !== 'undefined' && document && document.documentElement;
  const [width, setWidth] = useState(doc.clientWidth);
  useEffect(() => {
    const onResize = () => {
      setWidth(doc.clientWidth);
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  const forward = () => {
    if (index === items.length - 1) {
      return;
    }
    setForegroundVisible(true);
    setIndex(index + 1);
  };

  const back = () => {
    if (index === 0) {
      return;
    }
    setForegroundVisible(true);
    setIndex(index - 1);
  };

  return (
    <Background>
      <Container className="container-lg">
        <ParallaxContainer>
          <ImageContainer>
            <Layer
              alt="Stores"
              src={isMobile ? streetMobile : street}
              offset={steps[step]}
              expanded={foregroundExpanded}
            />
            <Layer
              alt="Trees, bikes and plants"
              src={isMobile ? middleMobile : middle}
              offset={midSteps[step]}
              expanded={foregroundExpanded}
            />
            <Layer
              alt="Trees and bikes"
              src={isMobile ? frontMobile : front}
              offset={frontSteps[step]}
              expanded={foregroundExpanded}
            />
          </ImageContainer>
          <OverlayBackground
            expanded={backgroundExpanded}
            screenWidth={width}
          />
          <OverlayForeground
            expanded={foregroundExpanded}
            visible={foregroundVisible}
            ref={foreground}
          />
          {streetSign && (
            <StreetSign
              alt={streetSign.name}
              fixed={streetSign.childImageSharp.fixed}
              visible={!foregroundVisible && step !== 0}
            />
          )}
          {items.map(
            (item, i) =>
              i === step && (
                <Overlay expanded={foregroundExpanded}>
                  <div>
                    {i === 0 && <h1>{item.header}</h1>}
                    {i !== 0 && (
                      <HeaderWithStatus>
                        <h4>{item.header}</h4>
                        {item.subheader && (
                          <div>
                            <Status
                              open={item.subheader
                                .toLowerCase()
                                .includes('open')}>
                              {item.subheader}
                            </Status>
                          </div>
                        )}
                      </HeaderWithStatus>
                    )}
                    {renderRichText(item.body)}
                  </div>
                  {i === 0 && (
                    <div>
                      <Button onClick={forward}>{item.cta}</Button>
                    </div>
                  )}
                  {i !== 0 && (
                    <Buttons>
                      <ArrowButton onClick={back}>
                        <LeftArrow />
                      </ArrowButton>
                      {i !== items.length - 1 && (
                        <ArrowButton onClick={forward}>
                          <RightArrow />
                        </ArrowButton>
                      )}
                    </Buttons>
                  )}
                </Overlay>
              )
          )}
        </ParallaxContainer>
      </Container>
    </Background>
  );
};
