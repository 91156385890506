import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';
import FrontPage from '../components/FrontPage/FrontPage';

const IndexPage = () => {
  const sections = [
    {
      name: 'Learn',
      element: '#learn'
    },
    {
      name: 'Listen',
      element: '#listen'
    },
    {
      name: 'Resources',
      element: '#resources'
    }
  ];

  const result = useStaticQuery(graphql`
    query {
      contentfulPageGeneral(contentful_id: { eq: "4Co5ZyphVGa40iKHTBebJ9" }) {
        identifier
        pageUrl
        pageTitle {
          raw
        }
        metaTitle
        metaKeywords
        metaDescription
        pageSections {
          ... on ContentfulSpecialParallaxCarousel {
            contentful_id
            specialParallaxCarouselItem {
              header
              subheader
              body {
                raw
              }
              cta
            }
          }
          ... on ContentfulSectionHero {
            contentful_id
            heroImage {
              fluid(quality: 90, maxWidth: 1600) {
                ...GatsbyContentfulFluid
              }
            }
            imageAlignment
            pageDisplayName
            header
            body {
              raw
            }
          }
          ... on ContentfulSectionBasic {
            contentful_id
            identifier
            header
            body {
              raw
            }
            primaryCta
            primaryCtaDestination {
              url
            }
            secondaryCta
            secondaryCtaDestination {
              url
            }
            components {
              ... on ContentfulSectionBasic {
                contentful_id
                identifier
                sectionImage {
                  fluid(quality: 90) {
                    ...GatsbyContentfulFluid
                  }
                }
                body {
                  raw
                }
                primaryCta
                primaryCtaDestination {
                  url
                }
              }
            }
          }
          ... on ContentfulSectionTiles {
            contentful_id
            header
            tiles {
              identifier
              header
              description {
                raw
              }
              primaryCta
              primaryCtaDestination {
                ... on ContentfulExternalLinks {
                  identifier
                  url
                }
              }
            }
          }
          ... on ContentfulSectionRelatedArticles {
            contentful_id
            header
            subheader
            displayAs
            relatedArticles {
              articleTitle
              articleTitleAsQuestion
              articleType
              articleCardImage {
                fluid(quality: 90) {
                  ...GatsbyContentfulFluid
                }
              }
              pageUrl
              articleCardSnippet
              articleCardButtonText
            }
          }
          ... on ContentfulSectionTabs {
            contentful_id
            identifier
            header
            tabs {
              identifier
              tabHeader
              tabBody {
                raw
              }
            }
          }
          ... on ContentfulSectionLegal {
            contentful_id
            legalDisclaimers {
              raw
            }
          }
        }
      }
    }
  `);

  const data = result.contentfulPageGeneral;

  const parallaxData = data.pageSections.find(
    section => section.contentful_id === '3JzsZF2F7KQVjYslDXSIJF'
  );

  const introData = data.pageSections.find(
    section => section.contentful_id === '4I1gwmZXhKFVIOdl0X2ZSX'
  );

  const articlesData = data.pageSections.find(
    section => section.contentful_id === '7Ip3s6S4KH715DFaSaBd7J'
  );

  const ctaData = data.pageSections.find(
    section => section.contentful_id === '2MWpZZoskmdyIOBkE8JXhM'
  );

  const listenData = data.pageSections.find(
    section => section.contentful_id === '3GNWq5lvCOngzRYuv9YBzG'
  );

  const financeData = data.pageSections.find(
    section => section.contentful_id === '26jh0gp1BbhejuYFu2OtR5'
  );

  const tabsData = data.pageSections.find(
    section => section.contentful_id === '6eN1V0DOU5fHxMGD90qRqb'
  );

  const legalData = data.pageSections.find(
    section => section.contentful_id === '2ka3CsdORDy0Me3CEMTM9T'
  );

  return (
    <Layout currentPage="Home">
      <SEO
        title={data.metaTitle}
        description={data.metaDescription}
        keywords={data.metaKeywords.split(', ')}
      />
      <FrontPage
        sections={sections}
        parallaxData={parallaxData}
        introData={introData}
        articlesData={articlesData}
        ctaData={ctaData}
        listenData={listenData}
        financeData={financeData}
        tabsData={tabsData}
        legalData={legalData}
      />
    </Layout>
  );
};

export default IndexPage;
