import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Nav, Navbar, NavItem, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import styled from 'styled-components';
import Link from '../Link';
import Menu from './Menu';
import LinkButton from '../Buttons/LinkButton';
import device from '../Device';
import ModalButton from '../Modal/ModalButton';
import ModalBody from '../Modal/OptionModal/ModalBody';
import getElementHeight from '../../../utils/getElementHeight';

const StyledNavbar = styled(Navbar)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  z-index: 1000;

  ${props =>
    props.isNavTop
      ? `
      position: fixed;
      top: 0;
      + div {
        padding-top: ${getElementHeight('#section-nav')}px;
      }
  `
      : ''}
`;

const StyledNavItem = styled(NavItem)`
  margin: 0;
  padding: 0;

  &.active-menu-item a {
    border-bottom: 4px solid #ca1905;
    font-weight: bold;
  }
`;

const StyledButton = styled(LinkButton)`
  @media ${device.max.md} {
    padding: 9px 15px;
    min-width: 88px;
    min-height: 44px;
  }

  @media ${device.only.lg} {
    min-width: 88px;
    min-height: 44px;
  }
`;

const JoinNav = styled(Nav)`
  margin: 0;
  padding: 0;

  @media ${device.max.md} {
    margin-right: 10px;
  }
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  ol {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;

      &.active {
        color: #000;
      }

      &.breadcrumb-item + .breadcrumb-item::before {
        color: #000;
        content: '>';
      }
    }
  }

  @media ${device.max.md} {
    max-width: 80px;

    li {
      &.breadcrumb-item:first-child {
        display: none;
      }

      &.breadcrumb-item + .breadcrumb-item::before {
        display: none;
      }
    }
  }
`;

export default ({ breadcrumbs, sections, cta, ctaDestination }) => {
  const [isNavTop, setNavTop] = useState(false);
  const mobileMenusOpen = useSelector(state => state.menu.mobileMenusOpen);

  useEffect(() => {
    const handler = () => {
      if (isNavTop) {
        const bodyPos = document.querySelector('#section-nav + div').offsetTop;
        if (window.pageYOffset < bodyPos) {
          setNavTop(false);
        }
      } else {
        const navPos = document.querySelector('#section-nav').offsetTop;
        if (window.pageYOffset >= navPos) {
          setNavTop(true);
        }
      }
    };

    window.addEventListener('scroll', handler);

    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [isNavTop]);

  return (
    <StyledNavbar
      id="section-nav"
      color="light"
      light
      expand="md"
      isNavTop={isNavTop}
      style={{
        zIndex: !mobileMenusOpen ? 10000 : 1,
        margin: 0,
        padding: 0
      }}>
      <div className="container-lg">
        <StyledBreadcrumb>
          {!!breadcrumbs &&
            breadcrumbs.map((breadcrumb, index) => (
              <BreadcrumbItem active={index === breadcrumbs.length - 1}>
                {breadcrumb.url ? (
                  <Link to={breadcrumb.url}>{breadcrumb.text}</Link>
                ) : (
                  breadcrumb.text
                )}
              </BreadcrumbItem>
            ))}
        </StyledBreadcrumb>
        <Menu sections={sections} />
        {cta && (
          <JoinNav>
            <StyledNavItem>
              {ctaDestination && ctaDestination.modalHeader && (
                <StyledButton as={ModalButton} label={cta}>
                  <ModalBody
                    header={ctaDestination.modalHeader}
                    body={ctaDestination.modalBody}
                    options={ctaDestination.subModals}
                  />
                </StyledButton>
              )}
              {ctaDestination && ctaDestination.url && (
                <StyledButton to={ctaDestination.url}>{cta}</StyledButton>
              )}
              {!ctaDestination && <StyledButton>{cta}</StyledButton>}
            </StyledNavItem>
          </JoinNav>
        )}
      </div>
    </StyledNavbar>
  );
};
