import React from 'react';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { INLINES } from '@contentful/rich-text-types';
import Link from '../../Common/Link';
import LearnMore from '../../Common/LearnMore';

const StyledRow = styled(Row)`
  padding-top: 40px;
`;

export default ({ selectedItem, resourceTypes }) => {
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        if (node.data.uri.includes('http')) {
          return (
            <LearnMore
              url={node.data.uri}
              text={children}
              external
              openNewTab
            />
          );
        }
        return <Link to={node.data.uri}>{children}</Link>;
      }
    }
  };

  return (
    <StyledRow>
      <Col xs={12}>
        {resourceTypes.map(
          (resource, index) =>
            selectedItem === resource.tabHeader && (
              <div key={index}>{renderRichText(resource.tabBody, options)}</div>
            )
        )}
      </Col>
    </StyledRow>
  );
};
